<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { saleListAPI, saleListAPISummary } from "@/api/shop/saleGuide/query"; //导购员查询
export default {
  name: "SaleQuery",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "1c531ce4-4dab-d7da-7a6f-a24e7e108799",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        getListApi: saleListAPI,
        getSummaryApi: saleListAPISummary,
        exportOption: {
          fastExportUrl: "/api/system/shop/guideMan/query/saleQueryExport",
          exportName: "导购员销售查询",
        },
        title: "导购员销售查询",
        search: [
          {
            label: "销售时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/商品编码/商品名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "guideManNos", label: "导购员编号" },
              { filter: "guideManNames", label: "导购员名称" },
              { filter: "billPosNos", label: "销售小票号" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listGuideMan",
            option: {
              filter: "guideManIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择导购员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "guideMan" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listBrand",
            option: {
              filter: "brandIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品品牌",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "brand" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          { label: "门店编号", prop: "shopNo", minWidth: 120 },
          { label: "门店名称", prop: "shopName", minWidth: 160 },
          { label: "导购员编号", prop: "guideManNo", minWidth: 120 },
          { label: "导购员名称", prop: "guideManName", minWidth: 160 },
          { label: "销售小票号", prop: "billPosNo", minWidth: 180 },
          { label: "销售时间", prop: "billDate", minWidth: 155 },
          { label: "商品编号", prop: "goodsNo", minWidth: 160 },
          { label: "商品名称", prop: "goodsName", minWidth: 180 },
          { label: "商品分类", prop: "categoryName", minWidth: 160 },
          { label: "条码", prop: "barcode", minWidth: 160 },
          { label: "品牌编号", prop: "brandNo", minWidth: 120 },
          { label: "品牌名称", prop: "brandName", minWidth: 120 },
          { label: "规格", prop: "goodsSpec", minWidth: 120 },
          { label: "单位", prop: "unitName", minWidth: 120 },
          { label: "销售数量", prop: "saleQty", minWidth: 100 },
          { label: "销售金额", prop: "saleMoney", minWidth: 100 },
          { label: "提成比例(%)", prop: "guideManCommissionRate", minWidth: 100 },
          { label: "提成金额", prop: "guideManCommissionMoney", minWidth: 100 },
        ],
        summary: ["saleQty", "saleMoney", "guideManCommissionMoney"],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "oepnDetail": //获取点击商品信息
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
